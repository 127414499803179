import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Alert, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import globalContext from '../context/globalContext';
import API from '../global/api';

const AddUser = props => {

    const context  = useContext(globalContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [marinas, setMarinas] = useState([]);

    const fetchMarinas  = async () => {

        setLoading(true);
        setError(null);

        try {

            const data = await API.req('/marinas', 'GET');
            setMarinas(data.result);
            
        } catch (error) {
            setError('An error occured loading the marinas');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchMarinas();
    }, [])


    const [form, setForm] = useState({
        email: '',
        id_organisation: '',
        is_global: false
    });


    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    const handleInputChange = (e) => {
        let newForm = JSON.parse(JSON.stringify(form));
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleIsGlobalChange = (e) => {
        let newForm = JSON.parse(JSON.stringify(form));
        newForm.is_global = e.target.checked;
        if(e.target.checked)
            newForm.id_organisation = '';
        setForm(newForm);
    }

    const handleAdd = async () => {
        setError(null);

        if(form.email.length === 0 || (form.id_organisation.length === 0 && form.is_global === false))
            setError('Please enter all fields');
        else {
            try {

                setLoading(true);

                // Start the test
                const data = await API.req(`/users`, 'POST', form);

                if(data.success === true){

                    setSuccess(true);
                    setForm({
                        email: '',
                        id_organisation: '',
                        is_global: false
                    });
                    if(typeof props.onSuccess === 'function')
                        props.onSuccess()

                }
                
            } catch (error) {
                setError('An error occured adding the user');
            } finally {
                setLoading(false);
            }
        }
    }


    return (
        <React.Fragment>

            <Dialog onClose={handleClose} open={props.open}>
                <DialogTitle>Add user</DialogTitle>
                <DialogContent>
                    <DialogContentText className='p-b'>
                        Adding a user will send them an email with a PIN to access the test tool for the marina you select below.
                    </DialogContentText>

                    {success && <Alert severity="success">The user was added and an invite sent</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                    
                    <div className="p-b">
                        <TextField
                            autoFocus
                            name="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={form.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="p-b">
                        <FormControl fullWidth variant="standard" disabled={form.is_global}>
                            <InputLabel id="select-marina-label">Select Marina</InputLabel>
                            <Select
                                labelId="select-marina-label"
                                name="id_organisation"
                                value={form.id_organisation}
                                label="Select Marina"
                                onChange={handleInputChange}
                            >
                                {marinas.map((val, idx) => (
                                    <MenuItem key={idx} value={val.id_organisation}>{val.organisation_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="p-b">
                        <FormControlLabel control={<Switch name="isGlobal" checked={form.is_global} onChange={handleIsGlobalChange} />} label="Global User" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAdd} disabled={loading} variant="contained">{loading ? <CircularProgress size={24} /> : 'Add'}</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}
export default AddUser;