import React, { useContext, useEffect, useState } from 'react';
import {InputLabel, MenuItem, FormControl, Select, Alert, IconButton} from '@mui/material';
import API from '../global/api';
import LoadingLable from '../components/loading-label';
import RefreshIcon from '@mui/icons-material/Refresh';
import globalContext from '../context/globalContext';
import { isNull } from '../global/helpers';

const SelectMarina = props => {

    const context  = useContext(globalContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [marinas, setMarinas] = useState([]);

    const handleChange = (event) => {
      context.setMarina(event.target.value)
    };

    const fetchMarinas  = async () => {

        setLoading(true);
        setError(null);

        try {

            const data = await API.req('/marinas', 'GET');
            setMarinas(data.result);
            if(data.result.length === 1)
                context.setMarina(data.result[0].value)
            
        } catch (error) {
            setError('An error occured loading the marinas');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchMarinas();
    }, [])

    return (
        <React.Fragment>

            {error && <Alert severity="error">{error}</Alert>}
            <LoadingLable loading={loading} title="Loading marinas" />

            {!loading && 
                <div className="flex-row align-center">
                    <FormControl fullWidth sx={{ width: 300 }}>
                        <InputLabel id="select-marina-label">Select Marina</InputLabel>
                        <Select
                            labelId="select-marina-label"
                            id="select-marina"
                            value={isNull(context.marina, '')}
                            label="Select Marina"
                            onChange={handleChange}
                        >
                            {marinas.map((val, idx) => (
                                <MenuItem key={idx} value={val.id_organisation}>{val.organisation_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <IconButton onClick={fetchMarinas}><RefreshIcon /></IconButton>
                </div>
            }

        </React.Fragment>
    )
}
export default SelectMarina;