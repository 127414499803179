import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import utilities from "../reducers/utilityReducer";
import services from "../reducers/serviceReducer";
import paused from "../reducers/pausedReducer";
import tests from "../reducers/testReducer";
import user from "../reducers/userReducer";

export default createStore(
    combineReducers({
        utilities,
        tests,
        user,
        services,
        paused
    }),
    {},
    applyMiddleware(thunk)
)