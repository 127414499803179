import API from '../global/api';

const TOKEN_KEY = '_bvtt';
const TOKEN_EXPIRES= '_bvte';

export function login (payload) {
    return async dispatch => {

        // Set the expiry date for the token
        localStorage.setItem(TOKEN_KEY, JSON.stringify(payload.token));
        localStorage.setItem(TOKEN_EXPIRES, JSON.stringify(payload.expires));

        // Login successful
        dispatch({
            type: 'USER_LOGIN',
            status: 'in',
            payload: payload
        })

    }
}

export function userValidate () {
    return async dispatch => {

        const token = localStorage.getItem(TOKEN_KEY);
        const expires = localStorage.getItem(TOKEN_EXPIRES);

        if(token !== null && expires !== null && new Date() < new Date(JSON.parse(expires))){


            try {

                const data = await API.req('/user', 'GET', {}, {
                    'x-access-token': JSON.parse(token)
                });

                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: {
                        token: JSON.parse(token),
                        expires: JSON.parse(expires),
                        user: data.result
                    }
                })
                
            } catch (error) {

                localStorage.removeItem(TOKEN_KEY);
                localStorage.removeItem(TOKEN_EXPIRES);

                dispatch({
                    type: 'USER_LOGIN',
                    status: 'error',
                    payload: error
                })

            }
           

        }


    }
}

export function logout () {

    return async dispatch => {

        //  Removed the current active profile, 
        //  this will save bugs when another person logs in
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_EXPIRES);

        // Logout successful
        dispatch({
            type: 'USER_LOGOUT',
            payload: null
        })

    }

}