import React, { useContext, useState, useEffect } from 'react';
import {Typography, Accordion, AccordionSummary, AccordionDetails, formControlUnstyledClasses} from '@mui/material';
import globalContext from '../context/globalContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PedestalUtilities from './pedestal-utilities';

const Pedestal = props => {

    const context  = useContext(globalContext);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (e, isExpanded) => {
      setExpanded(isExpanded);
    };

    return (
        <React.Fragment>

            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography>{props.pedestal.pedestal_reference}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {expanded && <PedestalUtilities id_pedestal={props.pedestal.id_pedestal} />}
                </AccordionDetails>

            </Accordion>

        </React.Fragment>
    )
}
export default Pedestal;