import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, {useContext, useState} from 'react';
import globalContext from '../context/globalContext';
import API from '../global/api';

const Container = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    maxWidth: 320,
    gap: '15px'
})

const Signin = props => {

    const context  = useContext(globalContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [form, setForm] = useState({
        pin: ''
    });

    const handleChangeInput = e => {
        let newForm = JSON.parse(JSON.stringify(form));
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    

    const handleKeyDown = e => {
        if (e.key === 'Enter')
            handleSignin();
    }

    const handleSignin  = async () => {

        setLoading(true);
        setError(null);

        try {

            setLoading(false);
            const data = await API.req('/user', 'POST', form);
            const expires = new Date(new Date().setTime(new Date().getTime() + (data.result.expires*1000)));
            context.login({
                ...data.result,
                expires: expires
            });
            
        } catch (error) {
            setLoading(false);
            setError(error);
        }

    }

    return (
        <React.Fragment>

            <Container>
                <Typography variant="h4">Sign in</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField name="pin" type="password" label="Pin" variant="filled" fullWidth value={form.pin} onChange={handleChangeInput} onKeyDown={handleKeyDown}/>
                <Button onClick={handleSignin} variant="contained" disabled={loading}>{loading  ? <CircularProgress size={24} /> : 'Sign in' }</Button>
                <Typography variant="caption">v{context.version}</Typography>
            </Container>

        </React.Fragment>
    )
}
export default Signin;