  
const userReducer = (state = {
    profile: null,
    status: 'out',
    msg: '',
    verifying: false
}, action) => {
    switch(action.type){
        case "USER_LOGIN":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'error'?action.payload:'',
                profile: action.status === 'in'?action.payload:null,
                verifying: false
            }
            break;
        case "USER_LOGOUT":
            state = {
                ...state,
                status: 'out',
                msg: '',
                profile: null,
                verifying: false
            }
            break;
        case "USER_VERIFYING":
            state = {
                ...state,
                verifying: action.payload
            }
            break;
        default:
    }
    return state;
}

export default userReducer;