import React from 'react';
import { AppBar, Toolbar, Typography, Alert } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import Images from '../assets/imgs';
import OptionsMenu from './options-menu';


const MainToolbar = props => {

    return (
        <React.Fragment>
            <AppBar position="fixed" sx={{bgcolor: props.connected ? 'primary.main' : 'error.dark'}}>
                <Toolbar>
                    <img src={Images.logo_white} alt="BerthVend" style={{width: 'auto', height: 40, marginRight: 10}} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Test Bench</Typography>
                    {props.connected ? <WifiIcon sx={{fontSize: 16}} /> : <WifiOffIcon sx={{fontSize: 16}} />}
                    <OptionsMenu {...props} />
                </Toolbar>

                {props.hasPaused && <Alert severity="warning" variant="filled" sx={{borderRadius: 0}}>Do not forget to resume your paused services before closing the test bench</Alert>}
            </AppBar>
        </React.Fragment>
    )
}
export default MainToolbar;