import { Dialog, DialogTitle, DialogContent, DialogActions, Alert, Table, TableHead, TableRow, TableCell, TableBody, AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import LoadingLable from '../components/loading-label';
import globalContext from '../context/globalContext';
import API from '../global/api';

import CloseIcon from '@mui/icons-material/Close';
import AddUser from './users-add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const ManageUsers = props => {

    const context  = useContext(globalContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);

    const [showAdd, setShowAdd] = useState(false);

    const fetchUsers  = async () => {

        setLoading(true);
        setError(null);

        try {

            const data = await API.req('/users', 'GET');
            setUsers(data.result);
            
        } catch (error) {
            setError('An error occured loading the users');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [])


    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }


    return (
        <React.Fragment>

            <Dialog onClose={handleClose} open={props.open} fullScreen>

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">Manage Users</Typography>
                        <div className="flex"></div>
                        <IconButton onClick={() => setShowAdd(true)}><GroupAddIcon /></IconButton>
                    </Toolbar>
                </AppBar>

                <LoadingLable loading={loading} />

                {error && <Alert severity="error">{error}</Alert>}

                {!loading && 
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">Is Global</TableCell>
                                <TableCell align="right">Marina</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">Disabled</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow
                                key={user.id_utility_test_user}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {user.email}
                                    </TableCell>
                                    <TableCell align="right">{Boolean(user.is_global) ? 'Yes' : 'No'}</TableCell>
                                    <TableCell align="right">{user.organisation_name}</TableCell>
                                    <TableCell align="right">{user.created}</TableCell>
                                    <TableCell align="right">{user.disabled}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }

                <AddUser open={showAdd} onClose={() => setShowAdd(false)} onSuccess={fetchUsers} />
            </Dialog>

        </React.Fragment>
    )
}
export default ManageUsers;