import { FormControlLabel, Switch} from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import globalContext from '../context/globalContext';

const StopOnPass = props => {

    const context  = useContext(globalContext);

    const [value, setValue] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.checked)
    }

    useEffect(() => {

        const preset = localStorage.getItem('bvtt-sop');

        if(preset !== null){
            const parsed = Boolean(JSON.parse(preset));
                setValue(parsed)
        }

    }, [])

    useEffect(() => {
        if(value !== null)
            localStorage.setItem('bvtt-sop', JSON.stringify(value));
    }, [value])


    return (
        <React.Fragment>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', paddingTop: 15, paddingBottom: 15}}>
                <FormControlLabel labelPlacement="start" control={<Switch checked={value} onChange={handleChange} />} label="Auto stop on pass" />
            </div>

        </React.Fragment>
    )
}
export default StopOnPass;