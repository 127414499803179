const testReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: []
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;

    switch(action.type){
        case "TESTS_LOAD":
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?action.payload:current_data
            }
            break;
        case "TEST_UPDATE":
            const idx = status === 'OK' ? current_data.findIndex(c => c.identity === action.payload.identity) : -1;
            
            if(status === 'OK' && idx > -1)
                current_data[idx] = action.payload;
            else
                current_data.push(action.payload);
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            break;
        case "TEST_CACHECLEAR":
            let newData = JSON.parse(JSON.stringify(current_data.filter(t => t.ended === null)));
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: newData
            }
            break;
        default:
    }
    return state;
}

export default testReducer;