import { Dialog, AppBar, Toolbar, IconButton, Typography, DialogContent, Slider, Button} from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import globalContext from '../context/globalContext';

import CloseIcon from '@mui/icons-material/Close';
import StopOnPass from './stop-on-pass';

const TimerSettings = props => {

    const context  = useContext(globalContext);

    const [value, setValue] = useState(5);


    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {

        const preset = localStorage.getItem('bvtt-timer');

        if(preset !== null){
            const parsed = parseInt(JSON.parse(preset));
            if(!isNaN(parsed))
                setValue(parsed)
        }

    }, [])

    useEffect(() => {
        if(value !== null)
            localStorage.setItem('bvtt-timer', JSON.stringify(value));
    }, [value])


    return (
        <React.Fragment>

            <Dialog onClose={handleClose} open={props.open}>

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography variant="h6">Timer Settings</Typography>
                        <div className="flex"></div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <Typography variant="body1" style={{marginBottom: 15}}>Adjust the amount of time each test runs for</Typography>
                    <Typography variant="h3" align="center">{value} Mins</Typography>
                    <Slider value={value} onChange={handleChange} step={1} min={1} max={60}/>
                    <StopOnPass />
                    <div className="flex-row">
                        <Button color="primary" onClick={() => setValue(5)}>Reset</Button>
                        <div className="flex"></div>
                        <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
                    </div>
                </DialogContent>

            </Dialog>

        </React.Fragment>
    )
}
export default TimerSettings;