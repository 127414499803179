import API from '../global/api';

export function servicesLoad (marina = null) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'SERVICES_LOAD',
            status: 'LOADING',
            payload: null
        })


        if(marina !== null){

            try {
  
                const data = await API.req(`/services/${marina}`, 'GET');
                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'OK',
                    payload: data.result
                })
                
            } catch (err) {

                dispatch({
                    type: 'SERVICES_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your services'
                })

            }
  
        }

    }
}

export function serviceUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'SERVICE_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}

export function servicesUpdate (payload) {
    return async dispatch => {

        dispatch({
            type: 'SERVICES_LOAD',
            status: 'OK',
            payload: payload
        })

    }
}