import React from 'react';
import {Menu, MenuItem, MenuList, ListItemIcon, ListItemText, IconButton, Switch, FormControlLabel, Divider} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import GroupIcon from '@mui/icons-material/Group';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AutorenewIcon from '@mui/icons-material/Autorenew';


const OptionsMenu = props => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  

  const handleSignOut = () => {
    if(typeof props.onSignOut === 'function')
        props.onSignOut();
  }

  return (
    <div>
        <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            onClick={handleClick}
            color="inherit"
        >
            <SettingsIcon />
        </IconButton>

        <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
                
            <MenuList>
                {props.isGlobal && 
                    <MenuItem onClick={() => props.setShowUsers(true)}>
                        <ListItemIcon><GroupIcon fontSize="small"  /></ListItemIcon>
                        <ListItemText primary="Manage Users" />
                    </MenuItem>
                }
                <MenuItem onClick={() => props.setShowTimeOptions(true)}>
                  <ListItemIcon><AccessAlarmIcon fontSize="small"  /></ListItemIcon>
                  <ListItemText primary="Timer Settings" />
                </MenuItem>
                <MenuItem onClick={props.freshLoad}>
                  <ListItemIcon><AutorenewIcon fontSize="small"  /></ListItemIcon>
                  <ListItemText primary="Clear cache" />
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon><LogoutIcon fontSize="small"  /></ListItemIcon>
                    <ListItemText primary="Sign out" />
                </MenuItem>
            </MenuList>

        </Menu>        
    </div>
  );
}

export default OptionsMenu