import Main from './context/main';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00ADEE',
      contrastText: '#fff'
    },
    success: {
      main: '#6CB33F',
      contrastText: '#fff',
      light: '#A9FF73',
      dark: '#5C9936'
    },
    error: {
      main: '#FF0000',
      contrastText: '#fff'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;
