const serviceReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: []
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;

    switch(action.type){
        case "SERVICES_LOAD":
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?action.payload:current_data
            }
            break;
        case "SERVICE_UPDATE":
            const idx = status === 'OK' ? current_data.findIndex(c => c.id_service === action.payload.id_service) : -1;
            
            if(status === 'OK' && idx > -1){
                if(action.payload.id_session !== null && action.payload.end_time === null)
                    current_data[idx] = action.payload;
                else
                    current_data.splice(idx, 1);
            }
            else {
                if(action.payload.id_session !== null && action.payload.end_time === null)
                    current_data.push(action.payload);
            }
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            break;
        default:
    }
    return state;
}

export default serviceReducer;