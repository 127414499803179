import React from 'react';
import {Typography, CircularProgress} from '@mui/material';

const LoadingLable = props => {


    return (
        <React.Fragment>
            {props.loading &&
                <div className="flex-row p-b p-t">
                    <CircularProgress size={24} />
                    <Typography>{props.title || 'Loading'}</Typography>
                </div>
            }
        </React.Fragment>
    )
}
export default LoadingLable;