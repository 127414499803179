import API from '../global/api';

export function testsLoad (marina = null) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TESTS_LOAD',
            status: 'LOADING',
            payload: null
        })


        if(marina !== null){

            try {
  
                const data = await API.req(`/tests/${marina}`, 'GET');
                dispatch({
                    type: 'TESTS_LOAD',
                    status: 'OK',
                    payload: data.result
                })
                
            } catch (err) {

                dispatch({
                    type: 'TESTS_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your tests'
                })

            }
  
        }

    }
}

export function testUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TEST_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}

export function testsUpdate (payload) {
    return async dispatch => {

        dispatch({
            type: 'TESTS_LOAD',
            status: 'OK',
            payload: payload
        })

    }
}

export function testClearCache () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TEST_CACHECLEAR',
            status: 'OK'
        })
        
    }
}