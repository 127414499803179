import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Prompt} from 'react-router-dom'
import GlobalContext from './globalContext';
import { styled } from '@mui/material/styles';
import MainToolbar from '../components/main-toolbar';
import SelectMarina from '../content/select-marina';
import ManageUsers from '../content/users-manage';
import Pedestals from '../content/pedestals';
import io from 'socket.io-client';
import Signin from '../content/signin';
import { login, logout, userValidate } from '../actions/userActions';
import { utilitiesLoad, utilityUpdate, utilitiesUpdate } from '../actions/utilityActions';
import { servicesLoad, serviceUpdate, servicesUpdate } from '../actions/serviceActions';
import { pausedLoad, pausedUpdate } from '../actions/pausedActions';
import { testsLoad, testUpdate, testsUpdate, testClearCache } from '../actions/testActions';
import { connect } from 'react-redux';
import TimerSettings from '../content/timer-settings';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  alignItems: 'center',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden'
})
  
const Inner = styled('div')({
  width: '96%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingBottom: 20,
  paddingTop: 140
});


var SOCKET;
var silent_refresh;
var silent_check;

const Main = props => {

  const version = process.env.REACT_APP_VERSION || '0.0.0';

  const [socketConnected, setSocketConnected] = useState(false);
  const [marina, setMarina] = useState(null);
  const [socketStations, setSocketStations] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const [hasPausedServices, setHasPausedServices] = useState(false);
  const [showTimeOptions, setShowTimeOptions] = useState(false);


  useEffect(() => {
      props.utilitiesLoad(marina);
      props.servicesLoad(marina);
      props.pausedLoad(marina);
  }, [marina])

  
  useEffect(() => {

    if(props.user.profile !== null){
      _socketListeners(true);

      if(typeof props.user.profile.token != 'undefined'){

        _checkUser();

      }

    } else {

      props.userValidate();

    }

  }, [props.user])



  useEffect(() => {
    if(props.paused.data.filter(p => p.resumed === null).length > 0)
      setHasPausedServices(true);
    else 
      setHasPausedServices(false);
  }, [props.paused])


  const freshLoad = () => {
    props.utilitiesLoad(marina);
    props.servicesLoad(marina);
    props.pausedLoad(marina);
    props.testClearCache();
  }
  



  const _checkUser = () => {
    if(props.user.profile.expires === null || (props.user.profile.expires !== null && new Date() > new Date(props.user.profile.expires))){
      props.logout();
    } else{
      if(typeof silent_check != 'undefined')
        clearTimeout(silent_check);
      
      silent_check = setTimeout(_checkUser, 10000);
    }
  }

  const _handleIncommingMessage = message => {
    switch(message.type){
      case 'paused':
        props.pausedUpdate(message.payload);
        break;
      case 'test':
        props.testUpdate(message.payload);
        break;
      case 'service':
        props.serviceUpdate(message.payload);
        break;
      case 'utility':
        props.utilityUpdate(message.payload);
        break;
      default:
        // Do nothing
    }

  }





  /**
   *  SOCKET CONNECTION
   */


  const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;

  const _socketListeners = (forceNew = false) => {

    if(forceNew && typeof SOCKET != 'undefined' && SOCKET !== null){
      SOCKET.close();
      SOCKET = null;
    }


    if(!SOCKET)
      SOCKET = io(SOCKET_ENDPOINT, {query: {token: props.user.profile.token}, autoConnect: false});

    if(typeof SOCKET !== 'undefined' && SOCKET !== null){

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$join'] === 'undefined')
        SOCKET.on('join', () => {
          console.log('join please')
          _joinRoom();
        })
    
      if(typeof SOCKET._callbacks['$message'] == 'undefined')
        SOCKET.on('message', (message) => {
          _handleIncommingMessage(message);
        })
  
  
      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$disconnect'] === 'undefined')
        SOCKET.on('disconnect', () => {
          console.log('disconnected');
          setSocketConnected(false);
        })


      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$connect_error'] === 'undefined')
        SOCKET.on('connect_error', () => {
          console.warn('CONNECTION ERROR: You are not authorised')
        });

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$connect'] === 'undefined')
        SOCKET.on('connect', () => {
          console.log('connected');
          _joinRoom();
          setSocketConnected(true);
        });


      if(!SOCKET.connected)
        SOCKET.connect();

    }

  }

  const _joinRoom = () => {
    if(typeof SOCKET !== 'undefined' && SOCKET !== null && props.user.profile !== null){
      SOCKET.emit('join', socketStations)
    }
  }

  const _checkRoomForStations = (id_utilities) => {

    let newSocketStations = JSON.parse(JSON.stringify(socketStations));
    id_utilities.map(id => {
      if(newSocketStations.indexOf(id) === -1)
        newSocketStations.push(id);
    })
    if(JSON.stringify(newSocketStations) !== JSON.stringify(socketStations))
      setSocketStations(newSocketStations)
    else
      _joinRoom();

  }


  const _kickLoad = () => {
    _joinRoom()
    props.servicesLoad(marina);
    props.pausedLoad(marina);
    props.testsLoad(marina);
  }


  useEffect(() => {
    _kickLoad();
  }, [socketStations]);




  return (
    <GlobalContext.Provider value={{
      ...props,
      version,
      marina,
      setMarina,
      freshLoad,
      setSocketStations,
      checkRoomForStations: _checkRoomForStations,
      kickLoad: _kickLoad
    }}>
      <Router>
        {/* Routing compnents */}
        <Container>

          
          {!props.user.profile && <Signin />}

          {props.user.profile && 
            <React.Fragment>
              <ManageUsers open={showUsers} onClose={() => setShowUsers(false)}/>
              <TimerSettings open={showTimeOptions} onClose={() => setShowTimeOptions(false)} />
              <MainToolbar 
                onSignOut={props.logout} 
                connected={socketConnected} 
                isGlobal={Boolean(props.user.profile.user.is_global)} 
                setShowUsers={setShowUsers} 
                setShowTimeOptions={setShowTimeOptions} 
                freshLoad={freshLoad}
                hasPaused={hasPausedServices}
                />
              <Inner>
                  <SelectMarina />
                  <Pedestals />
              </Inner>
            </React.Fragment>
          }

        </Container>
      </Router>
    </GlobalContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    utilities: state.utilities,
    tests: state.tests,
    user: state.user,
    services: state.services,
    paused: state.paused
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
    logout: (payload) => {
      dispatch(logout(payload));
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    utilitiesLoad: (payload) => {
      dispatch(utilitiesLoad(payload));
    },
    utilityUpdate: (payload) => {
        dispatch(utilityUpdate(payload));
    },
    utilitiesUpdate: (payload) => {
        dispatch(utilitiesUpdate(payload));
    },
    servicesLoad: (payload) => {
      dispatch(servicesLoad(payload));
    },
    serviceUpdate: (payload) => {
        dispatch(serviceUpdate(payload));
    },
    servicesUpdate: (payload) => {
        dispatch(servicesUpdate(payload));
    },
    pausedLoad: (payload) => {
      dispatch(pausedLoad(payload));
    },
    pausedUpdate: (payload) => {
        dispatch(pausedUpdate(payload));
    },
    testsLoad: (payload) => {
      dispatch(testsLoad(payload));
    },
    testUpdate: (payload) => {
        dispatch(testUpdate(payload));
    },
    testsUpdate: (payload) => {
        dispatch(testsUpdate(payload));
    },
    testClearCache: () => {
      dispatch(testClearCache())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);