import React, { useContext, useEffect, useState } from 'react';
import {Typography, Alert, Grid, Autocomplete, TextField, Button, IconButton, CircularProgress} from '@mui/material';
import API from '../global/api';
import LoadingLable from '../components/loading-label';
import globalContext from '../context/globalContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import Pedestal from './pedestal';

const Pedestals = props => {

    const context  = useContext(globalContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pedestals, setPedestals] = useState([]);
    const [pedestalItems, setPedestalItems] = useState([]);
    const [selectedPedestals, setSelectedPedestals] = useState([]);
    const [searchText, setSearchText] = useState('');

    const fetchPedestals  = async () => {
    
        setPedestals([]);
        setError(null);

        if(context.marina !== null){

            setLoading(true);
            try {

                const data = await API.req(`/pedestals/${context.marina}`, 'GET');
                setPedestals(data.result);
                let items = [];
                data.result.forEach(p => {
                    items.push({
                        label: p.pedestal_reference,
                        id: p.id_pedestal
                    })
                })
                setPedestalItems(items);
                
            } catch (error) {
                setError('An error occured loading the pedestals');
            } finally {
                setLoading(false);
            }

        }

    }

    useEffect(() => {

        fetchPedestals();

    }, [context.marina])


    const handleSelectPedestal = (e, value) => {
        setSearchText(value);
        if(value !== null){
            let newSelected = JSON.parse(JSON.stringify(selectedPedestals));
            const pedestal = pedestals.find(p => p.id_pedestal === value.id);
            if(typeof pedestal !== 'undefined'){
                const idx = newSelected.findIndex(p => p.id_pedestal === pedestal.id_pedestal);
                if(idx === -1)
                    newSelected.push(pedestal);

                setSelectedPedestals(newSelected);
                setSearchText('');

            }
        }
    }

    const handleAddAll = () => {
        setSelectedPedestals(JSON.parse(JSON.stringify(pedestals)));
    }


    const handleRemove = (idx) => {
        let newSelected = JSON.parse(JSON.stringify(selectedPedestals));
        newSelected.splice(idx, 1);
        setSelectedPedestals(newSelected);
    }


    const handleRemoveAll = () => {
        setSelectedPedestals([]);
    }
    

    useEffect(() => {

        let id_pedestals = [];
        selectedPedestals.map(p => id_pedestals.push(p.id_pedestal));

        let id_utilities = [];
        context.utilities.data.filter(u => id_pedestals.indexOf(u.id_pedestal) > -1).map(u => id_utilities.push(u.id_utility));
       
        context.setSocketStations(id_utilities);

    }, [selectedPedestals])

    return (
        <React.Fragment>

            {error && <Alert severity="error">{error}</Alert>}
            <LoadingLable loading={loading} title="Loading pedestals" />

            {!loading && 
                <div className="p-t">

                    <div className="flex-col">
                        <div className="flex-row align-center">
                            <Autocomplete
                                disablePortal
                                id="select-pedestal"
                                options={pedestalItems}
                                onChange={handleSelectPedestal}
                                value={searchText}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Add a pedestal"/>}
                            />
                            <IconButton onClick={fetchPedestals}><RefreshIcon /></IconButton>
                        </div>
                        <div className="flex-row">
                            {selectedPedestals.length < pedestals.length && <Button onClick={handleAddAll} size="small" variant="contained">Add all</Button>}
                            {selectedPedestals.length === pedestals.length && <Button onClick={handleRemoveAll} size="small" variant="contained">Remove all</Button>}
                        </div>
                    </div>


                    <div className="flex-row end">
                        {selectedPedestals.length > 0 && <IconButton onClick={() => context.kickLoad()} color="primary">{context.services.status === 'LOADING' ? <CircularProgress size={18} /> : <RefreshIcon />}</IconButton>}
                    </div>


                    <div className="p-t">

                        <Grid container spacing={2}>

                            {selectedPedestals.map((p, idx) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                                    <div className="flex-row">
                                        <div className="flex"></div>
                                        <Button onClick={() => handleRemove(idx)} size="small" sx={{textTransform: 'none'}}>Remove</Button>
                                    </div>
                                    <Pedestal pedestal={p} />
                                </Grid>
                            ))}
                            
                        </Grid>

                    </div>

                </div>
            }

            {context.marina === null &&
                <div className="flex-col justify-center align-center">
                    <Typography variant="subtitle1" color="textSecondary">No marina selected</Typography>
                </div>
            }

        </React.Fragment>
    )
}
export default Pedestals;