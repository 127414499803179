import { Avatar, Chip, CircularProgress, Tooltip, Typography } from '@mui/material';
import React, {useContext, useState} from 'react';
import globalContext from '../context/globalContext';
import API from '../global/api';

import PowerIcon from '@mui/icons-material/Power';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import WaterIcon from '@mui/icons-material/Water';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';



const ServicePausedIcon = props => {

    const context = useContext(globalContext);

    const avatarDefault = {
        width: 24, 
        height: 24
    }

    const avatarOn = {
        width: 24, 
        height: 24,
        bgcolor: 'primary.main'
    }


    const iconDefault = {
        fontSize: 18
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleUnpause = async () => {

        if(typeof props.test !== 'undefined' && props.test.is_paused){

            try {
                setLoading(true);
                // Stop the test
                const data = await API.req(`/test/unpause/${props.test.id_session}`, 'POST', {});
                if(data.success === true){
            
                    // Update the test with the session id
                    context.testUpdate(data.result);
    
                }
                
            } catch (error) {
                setError('An error occured unpausing the test');
            } finally {
                setLoading(false);
            }

        }

    }

    return (
        <Avatar sx={props.test.is_paused ? avatarDefault : avatarOn} onClick={handleUnpause}>{loading ? <CircularProgress size={18} /> : <PowerSettingsNewIcon sx={iconDefault} />}</Avatar>
    )
}



const PedestalUtilityTest = props => {

    const context = useContext(globalContext);
    const test = context.tests.data.find(t => t.id_utility === props.utility.id_utility);

    const avatarDefault = {
        width: 24, 
        height: 24
    }

    const avatarPass = {
        width: 24, 
        height: 24,
        bgcolor: 'success.main'
    }

    const avatarNotPresent = {
        width: 24, 
        height: 24,
        bgcolor: 'error.main'
    }

    const iconDefault = {
        fontSize: 18
    }

    const serviceStatusIcon = () => {
        switch(test.id_service_type){
            case 1:
                return <Tooltip title={`Electricity Vending`}><Avatar sx={test.vending ? avatarPass : avatarDefault}><PowerInputIcon sx={iconDefault} /></Avatar></Tooltip>;
            case 2:
                return <Tooltip title={`Water Vending`}><Avatar sx={test.vending ? avatarPass : avatarDefault}><WaterIcon sx={iconDefault} /></Avatar></Tooltip>;
            default:
                return null;
        }
    }

    const meterStatusIcon = () => {
        return <Tooltip title={`Meter reading detected`}><Avatar sx={test.meter > 0 ? avatarPass : avatarDefault}><AvTimerIcon sx={iconDefault} /></Avatar></Tooltip>;
    }


    const unlatchedStatusIcon = () => {
        return test.id_service_type === 1 ? <Tooltip title={`Latch sensor: ${test.unplug_latch === null ? 'Not Present' : test.unplug_latch ? 'Detected' : 'Not Detected'}`}><Avatar sx={test.unplug_latch ? avatarPass : test.unplug_latch === null ? avatarNotPresent : avatarDefault}><PowerIcon sx={iconDefault} /></Avatar></Tooltip> : null;
    }

    const breakerStatusIcon = () => {
        return test.id_service_type === 1 ? <Tooltip title={`RCBO Trip Sensor: ${test.breaker === null ? 'Not Present' : test.breaker ? 'Detected' : 'Not Detected'}`}><Avatar sx={test.breaker ? avatarPass : test.breaker === null ? avatarNotPresent : avatarDefault}><PowerOffIcon sx={iconDefault} /></Avatar></Tooltip> : null;
    }

    const testStatus = () => {
        switch(test.id_service_type){
            case 1:
                if(test.vending && test.meter > 0 && (test.unplug_latch || test.unplug_latch === null) && (test.breaker || test.breaker === null))
                    return <Chip label="PASS" color="success" size="small" variant="outlined"/>;
                break;
            case 2:
                if(test.vending && test.meter > 0)
                    return <Chip label="PASS" color="success" size="small" variant="outlined"/>;
                break;
            default:
                return null;
        }

        if (test.ended !== null)
            return <Chip label="FAIL" color="error" size="small" variant="outlined"/>;

        return <Chip label="TESTING" size="small" variant="outlined"/>
    }

    return (
        <React.Fragment>

            {test && 
                <div className="flex-row align-center">
                    {test.ended === null && <ServicePausedIcon test={test} />}
                    {serviceStatusIcon()}
                    {meterStatusIcon()}
                    {unlatchedStatusIcon()}
                    {breakerStatusIcon()}
                    <Typography variant="body2" color="GrayText">{test.meter}</Typography>
                    <div className="flex"></div>
                    {testStatus()}
                </div>
            }

        </React.Fragment>
    )

}
export default PedestalUtilityTest;